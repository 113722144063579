import "./src/styles/global.css";

const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://widget.clutch.co/static/js/widget.js");
  };
};

export const onInitialClientRender = () => {
  const script = document.createElement("script");
  script.setAttribute("defer", "");
  script.setAttribute("data-domain", "reardonweb.dev");
  script.setAttribute("src", "/js/script.js");
  document.body.appendChild(script);
};
